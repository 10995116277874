//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start you may want to use LoadingIndicator instead
import React from 'react';

import classNames from 'classnames';

import LogoSmall    from '@assets/images/logo_small.png';
import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    isLoading:             PropTypes.bool,
    loadingOverlayClicked: PropTypes.func,
    text:                  PropTypes.string,
};

const LoadingOverlay = ({
    isLoading             = false,
    loadingOverlayClicked = DefaultTypes.func,
    text                  = '',
}) => {
    return (
        <div
            className={classNames(
                styles.loadingOverlay,
                {
                    [styles.loadingOverlayVisible]: isLoading,
                },
            )}
        >
            <div
                className={styles.loadingOverlaySpin}
                aria-label={text}
            >
                <div onClick={loadingOverlayClicked}>
                    <img
                        src={LogoSmall}
                        alt={'logo_mall'}
                    />
                </div>
                <div
                    className={styles.loadingOverlayText}
                >
                    {text}
                </div>
            </div>
        </div>
    );
};

LoadingOverlay.propTypes = propTypes;

export default LoadingOverlay;
// TODO-Skeleton end
