//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { create } from 'apisauce';
import _          from 'lodash';

import AdditionalRequestsContext from '@api/context/additionalRequests';
import AddressContext            from '@api/context/address';
import AllergensContext          from '@api/context/allergens';
import AppSettingsContext        from '@api/context/appSettings';
import ConfigurationApiContext   from '@api/context/configuration';
import OrdersContext             from '@api/context/order';
import OrderProductsContext      from '@api/context/orderProduct';
import ProductAdditionsContext   from '@api/context/productAdditions';
import ProductsApiContext        from '@api/context/products';
import RestaurantsContext        from '@api/context/restaurants';
import TimeSlotsContext          from '@api/context/timeSlots';
import UserApiContext            from '@api/context/user';
import ApiConfiguration          from '@constants/Api';
import MimeTypes                 from '@constants/MimeTypes';
import Hydra                     from '@helper/Hydra';

export const getBaseUrlWithUrl = (baseUrl, url = '') => {
    let urlSuffix = url;

    if (
        baseUrl.endsWith('/') &&
        urlSuffix.startsWith('/')
    ) {
        urlSuffix = urlSuffix.substring(1);
    }

    return baseUrl + urlSuffix;
};

export const createInstance = (host, apiPath) => {
    ApiConfiguration.checkBackendUrl(host);

    const api = create({
        baseURL: `${host}${apiPath}`,
        timeout: 30000,
        headers: {
            'accept':       MimeTypes.jsonld,
            'Content-Type': MimeTypes.json,
        },
    });

    api.host   = host;
    api.getUrl = (url = '', baseUrl = api.getBaseURL()) => getBaseUrlWithUrl(baseUrl, url);

    api.addResponseTransform((response) => {
        if (response.ok) {
            if (Hydra.isHydraResponse(response.data)) {
                response.data = Hydra.removeHydraFromObject(response.data);
            }
        } else {
            console.warn('Api-Warning:', response.problem);
        }

        return response;
    });

    api.addMonitor((response) => {
        const responseData = _.get(response, 'data', {});

        if (
            !response.ok &&
            _.has(responseData, 'error') &&
            Notification.translationExistsForKey(responseData.error)
        ) {
            Notification.error(_.get(responseData, 'error'));

            responseData.showedCustomNotification = true;
        }
    });

    return api;
};

export const Api = createInstance(ApiConfiguration.getBackendUrl(), '/');

export const getUrl = (url = '') => (
    getBaseUrlWithUrl(Api.getBaseURL(), url)
);

export const context = {
    configuration:      ConfigurationApiContext(Api),
    user:               UserApiContext(Api),
    products:           ProductsApiContext(Api),
    appSettings:        AppSettingsContext(Api),
    allergens:          AllergensContext(Api),
    additionalRequests: AdditionalRequestsContext(Api),
    timeSlots:          TimeSlotsContext(Api),
    productAdditions:   ProductAdditionsContext(Api),
    restaurants:        RestaurantsContext(Api),
    orders:             OrdersContext(Api),
    address:            AddressContext(Api),
    orderProducts:      OrderProductsContext(Api),
};
