//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

const initialState = Object.freeze({
    installWebAppBannerDismissed: false,
});

const webAppBannerSlice = createSlice({
    name:     'smartUpdater',
    initialState,
    reducers: {
        dismissInstallWebAppBanner: (state) => {
            return update(state, {
                installWebAppBannerDismissed: {
                    $set: true,
                },
            });
        },
    },
});

export const WebAppBannerActions = webAppBannerSlice.actions;

export const WebAppBannerReducer = webAppBannerSlice.reducer;

export const useWebAppBanner = (dispatch) => bindActionCreators(WebAppBannerActions, dispatch);

export default webAppBannerSlice;
// TODO-Skeleton end
