//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import React from 'react';

import classNames from 'classnames';

import DefaultTypes          from '@components/DefaultTypes';
import PropTypes             from '@components/PropTypes';
import Icon                  from '@stateless/atomic/Icon';
import ColorButtonColorTheme from '@stateless/composed/ColorButton/ColorButtonColorTheme';

import styles from './styles.module.scss';

const propTypes = {
    colorTheme:    PropTypes.oneOfObjectKeys(ColorButtonColorTheme),
    iconTypeLeft:  PropTypes.iconType,
    iconTypeRight: PropTypes.iconType,
    onClick:       PropTypes.func,
    text:          PropTypes.string,
};

const ColorButton = ({
    colorTheme    = ColorButtonColorTheme.white,
    iconTypeLeft  = null,
    iconTypeRight = null,
    onClick       = DefaultTypes.func,
    text          = null,
}) => {
    function renderIcon(propertyValue, extraClassName) {
        if (propertyValue) {
            return (
                <span
                    className={classNames(
                        styles.iconWrapper,
                        extraClassName,
                    )}
                >
                    <Icon
                        iconType={propertyValue}
                    />
                </span>
            );
        }

        return null;
    }

    function renderIconLeft() {
        return renderIcon(
            iconTypeLeft,
            styles.iconWrapperLeft,
        );
    }

    function renderIconRight() {
        return renderIcon(
            iconTypeRight,
            styles.iconWrapperRight,
        );
    }

    return (
        <button
            className={classNames(
                styles.colorButton,
                {
                    [styles.colorButtonColorThemeDefault]: colorTheme === ColorButtonColorTheme.default,
                    [styles.colorButtonColorThemeOrange]:  colorTheme === ColorButtonColorTheme.orange,
                },
            )}
            onClick={onClick}
        >
            {renderIconLeft()}
            {text}
            {renderIconRight()}
        </button>
    );
};

ColorButton.propTypes = propTypes;

export default ColorButton;
// TODO-Skeleton end
