//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n      from 'i18next';
import { put }   from 'redux-saga/effects';
import { call }  from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';

import * as Api                 from '@api/index';
import Hydra                    from '@helper/Hydra';
import Notification             from '@helper/Notification';
import SagaStateHelper          from '@helper/SagaStateHelper';
import { ConfigurationActions } from '@slices/configuration';
import { OrdersActions }        from '@slices/orders';
import { selectOrderDate }      from '@store/selectors/orders';

const ORDER_FETCH_INTERVAL_IN_MILLISECONDS = 1000 * 10;

function* fetchOrders() {
    const date     = yield SagaStateHelper.selectBySelector(selectOrderDate);
    const response = yield call(Api.context.orders.fetch, date);

    if (response.ok) {
        const orders = Hydra.getMembersFromResponse(response.data);

        yield put(OrdersActions.fetchOrdersSucceeded({
            orders,
        }));
    } else {
        yield put(ConfigurationActions.fetchConfigurationFailed());
    }
}

function* fetchOrder(action) {
    const { iri }  = action.payload;
    const response = yield call(Api.context.orders.get, iri);

    if (response.ok) {
        const order = response.data;

        yield put(OrdersActions.fetchOrderSucceeded({
            order,
        }));
    }
}

function* updateOrder(action) {
    const { iri, values } = action.payload;
    const response        = yield call(
        Api.context.orders.update,
        iri,
        values,
    );

    if (response.ok) {
        const order = response.data;

        yield put(OrdersActions.updateOrderSucceeded({
            order,
        }));
    } else {
        Notification.error(I18n.t('orderStatusUpdateFailed'));
    }
}

function* fetchOrdersInInterval() {
    yield put(OrdersActions.fetchOrders());
    yield delay(ORDER_FETCH_INTERVAL_IN_MILLISECONDS);
    yield put(OrdersActions.fetchOrdersInInterval());
}

export default {
    fetchOrder,
    fetchOrders,
    fetchOrdersInInterval,
    updateOrder,
};
