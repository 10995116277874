//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';
import objectHash      from 'object-hash';

const initialState = Object.freeze({
    inventory: {},
});

const changeTrackerSlice = createSlice({
    name:     'changeTracker',
    initialState,
    reducers: {
        checkChanges:        (state, action) => {
        },
        saveReferenceObject: (state, action) => {
            const payload                            = _.get(action, 'payload', {});
            const { referenceObject, configuration } = payload;
            const { ignoredCompareField }            = configuration;
            const comparableObject                   = _.omit(referenceObject, ignoredCompareField);
            const referenceHash                      = objectHash(comparableObject);

            return update(state, {
                [payload.key]: {
                    $set: referenceHash,
                },
            });
        },
    },
});

export const ChangeTrackerActions = changeTrackerSlice.actions;

export const ChangeTrackerReducer = changeTrackerSlice.reducer;

export default changeTrackerSlice;
