//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put } from 'redux-saga/effects';

import { TimeSlotsActions } from '@slices/timeSlots';

function* windowGotFocus() {
    // You may do something here (e.g. fetching data)
}

function* windowBecameVisible() {
    // You may do something here (e.g. fetching data)
    yield put(TimeSlotsActions.fetchTimeSlots());
}

export default {
    windowGotFocus,
    windowBecameVisible,
};
