//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    isAuthenticated: false,
    password:        null,
});

const stagingAuthenticationSlice = createSlice({
    name:     'stagingAuthentication',
    initialState,
    reducers: {
        authenticate:                  LoadingLevelHelper.increaseLoading(() => {
        }),
        authenticationPasswordChanged: (state, action) => {
            const { password } = action.payload;

            return update(state, {
                password: {
                    $set: password,
                },
            });
        },
        authenticateSucceeded:         LoadingLevelHelper.decreaseLoading((state, action) => {
            const isAuthenticated = _.get(action, 'payload.isAuthenticated');

            return update(state, {
                isAuthenticated: {
                    $set: isAuthenticated,
                },
                password:        {
                    $set: null,
                },
            });
        }),
        reset:                         (state) => {
            return update(
                state,
                {
                    $set: initialState,
                },
            );
        },
    },
});

export const StagingAuthenticationActions = stagingAuthenticationSlice.actions;

export const StagingAuthenticationReducer = stagingAuthenticationSlice.reducer;

export const useStagingAuthentication = (dispatch) => bindActionCreators(StagingAuthenticationActions, dispatch);

export default stagingAuthenticationSlice;
// TODO-Skeleton end
