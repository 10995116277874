//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import React from 'react';

import I18n from 'i18next';

import ContentWrapper from '@stateless/atomic/ContentWrapper';
import Headline       from '@stateless/atomic/Headline';
import HeadlineType   from '@stateless/atomic/Headline/HeadlineType';
import PageTitle      from '@stateless/composed/PageTitle';

import styles from './styles.module.scss';

const NotFound = () => {
    return (
        <>
            <PageTitle
                title={I18n.t('pageNotFound')}
            />
            <ContentWrapper>
                <div className={styles.notFoundScreen}>
                    <Headline
                        title={I18n.t('error_404')}
                        type={HeadlineType.headline1}
                    />
                    <p>
                        {I18n.t('pageNotFound')}
                    </p>
                </div>
            </ContentWrapper>
        </>
    );
};

export default NotFound;
// TODO-Skeleton end
