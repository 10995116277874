//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _         from 'lodash';
import { put }   from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import { call }  from 'redux-saga/effects';

import * as Api              from '@api/index';
import Routes                from '@constants/Routes';
import DateTime              from '@helper/DateTime';
import Notification          from '@helper/Notification';
import SagaStateHelper       from '@helper/SagaStateHelper';
import Token                 from '@helper/Token';
import { AlertBoxActions }   from '@slices/alertBox';
import { LoadingActions }    from '@slices/loading';
import { NavigationActions } from '@slices/navigation';
import { UserActions }       from '@slices/user';

const afterLoginRoute = Routes.home;

/**
 * All routes that are defined in this array are reachable without the
 * requirement of a active/valid session. If no session is present on
 * a route that is not in this array, the user is automatically redirected
 * to Routes.login (in the restoreToken-saga).
 *
 * @type {(string)[]}
 */
const routesWithoutSession = [
    Routes.login,
];

function* requestLogin(email, password) {
    yield put(AlertBoxActions.clearAlerts());

    const response = yield call(
        Api.context.user.login,
        email,
        password,
    );

    if (response.ok) {
        const { lastLogin } = response.data;
        const { token }     = response.data;
        const { username }  = response.data;
        const { userId }    = response.data;

        Api.context.user.setToken(token);

        yield put(UserActions.loginSucceeded({
            lastLogin,
            token,
            username,
            userId,
        }));
    } else {
        yield put(UserActions.loginFailed());
    }
}

function* login(action) {
    const { username, password } = action.payload;
    const user                   = yield SagaStateHelper.selectFromState('user');
    const usernameToUse          = username || user.username;
    const passwordToUse          = password || user.password;

    yield requestLogin(usernameToUse, passwordToUse);
}

function* loginWithCredentials(action) {
    const { username, password } = action.payload;

    yield put(UserActions.login({
        username,
        password,
    }));
}

function* loginFailed() {
    Notification.error('loginError');
}

function* logout() {
    Api.context.user.setToken(null);
    yield put(LoadingActions.resetOverlay());
    yield put(NavigationActions.redirect({
        route: Routes.login,
    }));
}

function* logoutAfterTokenExpiration() {
    const userToken               = yield SagaStateHelper.selectFromState('user', 'token');
    const timeTillExpiration      = Token.getTimeTillExpiration(userToken);
    const daysToWaitForExpiration = 7;
    const sevenDaysInMilliseconds = DateTime.getDaysInMilliseconds(daysToWaitForExpiration);

    if (timeTillExpiration < sevenDaysInMilliseconds) {
        console.log('User: waiting for the token to expire up to:', `${daysToWaitForExpiration} days`);

        yield delay(timeTillExpiration);
        yield put(UserActions.logout());
    } else {
        console.log('User: skip waiting for the token to expire because it is more than:', `${daysToWaitForExpiration} days`);
    }
}

function* loginSucceeded() {
    yield put(NavigationActions.redirect({
        route: afterLoginRoute,
    }));
    yield* logoutAfterTokenExpiration();
}

function* restoreToken() {
    const pathname        = SagaStateHelper.selectFromState(
        'router',
        'location',
        'pathname',
    );
    const browserPathname = window.location.pathname;
    const user            = yield SagaStateHelper.selectFromState('user');

    if (user.token) {
        if (Token.isValidJWTToken(user.token)) {
            Api.context.user.setToken(user.token);

            if (
                pathname === Routes.login ||
                browserPathname === Routes.login
            ) {
                yield put(NavigationActions.redirect({
                    route: afterLoginRoute,
                }));
            }

            yield* logoutAfterTokenExpiration();
        } else {
            yield put(UserActions.logout());
        }
    } else if (
        routesWithoutSession.indexOf(pathname) === -1 &&
        routesWithoutSession.indexOf(browserPathname) === -1
    ) {
        // This delay is important otherwise the redirect will not work properly
        yield delay(50);
        yield put(NavigationActions.redirect({
            route: Routes.login,
        }));
    }
}

function* fetchUser() {
    const userIri  = yield SagaStateHelper.selectFromState('user', 'iri');
    const response = yield call(
        Api.context.user.fetchUser,
        userIri,
    );

    if (response.ok) {
        const userProfile = _.get(response, 'data');

        yield put(UserActions.fetchUserSucceeded({
            userProfile,
        }));
    } else {
        yield put(UserActions.fetchUserFailed());
    }
}

function* fetchUserFailed() {
    console.log('fetchUserFailed: Failed to fetch user data');
}

function* fetchUserSucceeded() {
    console.log('fetchUserSucceeded: Successfully fetched user data');
}

export default {
    login,
    loginFailed,
    loginSucceeded,
    loginWithCredentials,
    logout,
    restoreToken,
    fetchUser,
    fetchUserFailed,
    fetchUserSucceeded,
};
