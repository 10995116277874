//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { connectRouter }      from 'connected-react-router';
import { combineReducers }    from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import { AdditionalRequestsReducer }    from '@slices/additionalRequests';
import { AlertBoxReducer }              from '@slices/alertBox';
import { AllergensReducer }             from '@slices/allergens';
import { AppSettingsReducer }           from '@slices/appSettings';
import { ConfigurationReducer }         from '@slices/configuration';
import { LoadingReducer }               from '@slices/loading';
import { OrdersReducer }                from '@slices/orders';
import { OverlayReducer }               from '@slices/overlay';
import { ProductAdditionsReducer }      from '@slices/productAdditions';
import { ProductsReducer }              from '@slices/products';
import { RestaurantsReducer }           from '@slices/restaurants';
import { SmartUpdaterReducer }          from '@slices/smartUpdater';
import { StagingAuthenticationReducer } from '@slices/stagingAuthentication';
import { TimeSlotsReducer }             from '@slices/timeSlots';
import { UserReducer }                  from '@slices/user';
import { WindowReducer }                from '@slices/window';

export default (history) => withReduxStateSync(combineReducers({
    alertBox:              AlertBoxReducer,
    configuration:         ConfigurationReducer,
    loading:               LoadingReducer,
    smartUpdater:          SmartUpdaterReducer,
    overlay:               OverlayReducer,
    restaurants:           RestaurantsReducer,
    stagingAuthentication: StagingAuthenticationReducer,
    window:                WindowReducer,
    appSettings:           AppSettingsReducer,
    allergens:             AllergensReducer,
    additionalRequests:    AdditionalRequestsReducer,
    user:                  UserReducer,
    timeSlots:             TimeSlotsReducer,
    products:              ProductsReducer,
    orders:                OrdersReducer,
    productAdditions:      ProductAdditionsReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
}));
