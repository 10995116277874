//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { all }             from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';
import { takeEvery }       from 'redux-saga/effects';

import { AdditionalRequestsActions }    from '@slices/additionalRequests';
import { AlertBoxActions }              from '@slices/alertBox';
import { AllergensActions }             from '@slices/allergens';
import { AppSettingsActions }           from '@slices/appSettings';
import { ConfigurationActions }         from '@slices/configuration';
import { LoadingActions }               from '@slices/loading';
import { NavigationActions }            from '@slices/navigation';
import { OrderProductsActions }         from '@slices/orderProducts';
import { OrdersActions }                from '@slices/orders';
import { ProductAdditionsActions }      from '@slices/productAdditions';
import { ProductsActions }              from '@slices/products';
import { RestaurantsActions }           from '@slices/restaurants';
import { SmartUpdaterActions }          from '@slices/smartUpdater';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';
import { TimeSlotsActions }             from '@slices/timeSlots';
import { UserActions }                  from '@slices/user';
import { WindowActions }                from '@slices/window';

import AdditionalRequestsSagas    from './additionalRequests';
import AlertBoxSagas              from './alertBox';
import AllergensSagas             from './allergens';
import AppSettingsSagas           from './appSettings';
import ConfigurationSagas         from './configuration';
import LoadingSagas               from './loading';
import NavigationSagas            from './navigation';
import OrderProductsSagas         from './orderProducts';
import OrdersSagas                from './orders';
import PreLoadSagas               from './preload';
import ProductAdditionsSagas      from './productAdditions';
import ProductsSagas              from './products';
import RestaurantsSagas           from './restaurants';
import SmartUpdaterSagas          from './smartUpdater';
import StagingAuthenticationSagas from './stagingAuthentication';
import TimeSlotsSagas             from './timeSlots';
import UserSagas                  from './user';
import WindowSagas                from './window';

const callAlertBoxSagas = () => {
    return [
        // @formatter:off
        takeLatest([AlertBoxActions.showErrorAlertTranslated().type],   AlertBoxSagas.showErrorAlertTranslated),
        takeLatest([AlertBoxActions.showSuccessAlertTranslated().type], AlertBoxSagas.showSuccessAlertTranslated),
        // @formatter:on
    ];
};

const callConfigurationSagas = () => {
    return [
        // @formatter:off
        takeLatest([ConfigurationActions.fetchConfiguration().type], ConfigurationSagas.fetchConfiguration),
        // @formatter:on
    ];
};

const callSmartUpdaterSagas = () => {
    return [
        // @formatter:off
        takeLatest([SmartUpdaterActions.reloadData().type],    SmartUpdaterSagas.reloadData),
        takeLatest([SmartUpdaterActions.reloadAllData().type], SmartUpdaterSagas.reloadAllData),
        // @formatter:on
    ];
};

const callStagingAuthenticationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.resetState().type],              StagingAuthenticationSagas.reset),
        takeLatest([StagingAuthenticationActions.authenticate().type], StagingAuthenticationSagas.authenticate),
        // @formatter:on
    ];
};

const callLoadingSagas = () => {
    return [
        // @formatter:off
        takeLatest([LoadingActions.overlayClicked().type], LoadingSagas.overlayClicked),
        // @formatter:on
    ];
};

const callNavigationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.redirect().type], NavigationSagas.redirect),
        // @formatter:on
    ];
};

const callLocationChangeSagas = () => {
    return [
        // @formatter:off
        takeLatest([LOCATION_CHANGE], NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE], LoadingSagas.resetClickCount),
        // @formatter:on
    ];
};

const callWindowSagas = () => {
    return [
        // @formatter:off
        takeLatest([WindowActions.windowBecameVisible().type], WindowSagas.windowBecameVisible),
        takeLatest([WindowActions.windowGotFocus().type],      WindowSagas.windowGotFocus),
        // @formatter:on
    ];
};

const callUserSagas = () => {
    return [
        // @formatter:off
        takeLatest([UserActions.login().type],                UserSagas.login),
        takeLatest([UserActions.loginWithCredentials().type], UserSagas.loginWithCredentials),
        takeLatest([UserActions.loginFailed().type],          UserSagas.loginFailed),
        takeEvery([UserActions.loginSucceeded().type],        UserSagas.loginSucceeded),
        takeEvery([UserActions.loginSucceeded().type],        PreLoadSagas.preLoadData),
        takeLatest([UserActions.tryRestoreToken().type],      UserSagas.restoreToken),
        takeLatest([UserActions.logout().type],               UserSagas.logout),
        takeEvery([UserActions.fetchUser().type],             UserSagas.fetchUser),
        takeEvery([UserActions.fetchUserFailed().type],       UserSagas.fetchUserFailed),
        takeEvery([UserActions.fetchUserSucceeded().type],    UserSagas.fetchUserSucceeded),

        // @formatter:on
    ];
};

const callRehydrateSagas = () => {
    return [
        // @formatter:off
        takeLatest([REHYDRATE], LoadingSagas.rehydrate),
        takeLatest([REHYDRATE], PreLoadSagas.preLoadData),
        // @formatter:on
    ];
};

const callAppSettingsSagas = () => {
    return [
        // @formatter:off
        takeLatest([AppSettingsActions.fetchAppSettings().type],          AppSettingsSagas.fetchAppSettings),
        takeLatest([AppSettingsActions.fetchAppSettingsSucceeded().type], AppSettingsSagas.fetchAppSettingsSucceeded),
        takeLatest([AppSettingsActions.updateAppSetting().type],          AppSettingsSagas.updateAppSetting),
        // @formatter:on
    ];
};

const callProductSagas = () => {
    return [
        // @formatter:off
        takeLatest([ProductsActions.fetchProducts().type], ProductsSagas.fetchProducts),
        // @formatter:on
    ];
};

const callAllergensSagas = () => {
    return [
        // @formatter:off
        takeLatest([AllergensActions.fetchAllergens().type], AllergensSagas.fetchAllergens),
        // @formatter:on
    ];
};

const callAdditionalRequestsSagas = () => {
    return [
        // @formatter:off
        takeLatest([AdditionalRequestsActions.fetchAdditionalRequests().type], AdditionalRequestsSagas.fetchAdditionalRequests),
        // @formatter:on
    ];
};

const callProductAdditionsSagas = () => {
    return [
        // @formatter:off
        takeLatest([ProductAdditionsActions.fetchProductAdditions().type], ProductAdditionsSagas.fetchProductAdditions),
        // @formatter:on
    ];
};

const callTimeSlotsSagas = () => {
    return [
        // @formatter:off
        takeLatest([TimeSlotsActions.fetchTimeSlots().type], TimeSlotsSagas.fetchTimeSlots),
        // @formatter:on
    ];
};

const callOrdersSagas = () => {
    return [
        // @formatter:off
        takeLatest([OrdersActions.fetchOrders().type],             OrdersSagas.fetchOrders),
        takeLatest([OrdersActions.fetchOrder().type],              OrdersSagas.fetchOrder),
        takeLatest([OrdersActions.updateOrder().type],             OrdersSagas.updateOrder),
        takeLatest([OrdersActions.setSelectedDate().type],         OrdersSagas.fetchOrders),
        takeLatest([OrdersActions.fetchOrdersInInterval().type],   OrdersSagas.fetchOrdersInInterval),
        // @formatter:on
    ];
};

const callOrderProductSagas = () => {
    return [
        // @formatter:off
        takeLatest([OrderProductsActions.updateOrderProductStatus().type], OrderProductsSagas.updateOrderProductStatus),
        // @formatter:on
    ];
};

const callRestaurantsSaga = () => {
    return [
        // @formatter:off
        takeLatest([RestaurantsActions.fetchRestaurants().type],          RestaurantsSagas.fetchRestaurants),
        takeLatest([RestaurantsActions.fetchRestaurantsSucceeded().type], RestaurantsSagas.fetchRestaurantsSucceeded),
        // @formatter:on
    ];
};

function* root() {
    yield all([
        ...callAlertBoxSagas(),
        ...callConfigurationSagas(),
        ...callSmartUpdaterSagas(),
        ...callStagingAuthenticationSagas(),
        ...callLoadingSagas(),
        ...callNavigationSagas(),
        ...callLocationChangeSagas(),
        ...callWindowSagas(),
        ...callRehydrateSagas(),
        ...callProductSagas(),
        ...callAppSettingsSagas(),
        ...callAllergensSagas(),
        ...callAdditionalRequestsSagas(),
        ...callProductAdditionsSagas(),
        ...callTimeSlotsSagas(),
        ...callOrdersSagas(),
        ...callRestaurantsSaga(),
        ...callUserSagas(),
        ...callOrderProductSagas(),
    ]);
}

export default {
    root,
};
