//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const english = Object.freeze({
    and:                     'and',
    more:                    'More',
    no:                      'No',
    oclock:                  'o\'clock',
    or:                      'or',
    pageTitle:               'Clausgemacht Küchen-App',
    versionNumberDateFormat: 'DD.MM.YYYY HH:mm:ss',
    yes:                     'Yes',
    // TODO-Skeleton start
    //
    // BEWARE: Do NOT add any entry here. If you want to add a new entry,
    // please add it above the "TODO-Skeleton start" comment since
    // otherwise it is not clear which language keys are actually
    // used in this app.
    //
    // Also move up any key you use.
    alertBoxErrorText:             'Error',
    alertBoxErrorTextTranslated:   'Error (translated automatically)',
    alertBoxSuccessText:           'Success',
    alertBoxSuccessTextTranslated: 'Success (translated automatically)',
    autoLogin:                     'Auto Login',
    back:                          'Back',
    bookmarkBannerText:            'Hint: Bookmark this page to get offline access to the app',
    countDownDay:                  'day',
    countDownDay_plural:           'days',
    countDownHour:                 'hour',
    countDownHours:                'hours',
    countDownMinute:               'minute',
    countDownMinutes:              'minutes',
    countDownSecond:               'second',
    countDownSeconds:              'seconds',
    decimalSeparator:              '.',
    error_404:                     '404',
    euroSign:                      '€',
    example404:                    'Example 404',
    exampleAlertBox:               'Example Alertbox',
    exampleAlertBoxAll:            'Example Alertbox all',
    exampleAlertBoxSuccess:        'Example Alertbox success',
    exampleBreadCrumbBar:          'Example Breadcrumbbar',
    exampleButtons:                'Example buttons',
    exampleCheckBox:               'Example Checkbox',
    exampleConsole:                'Example Console',
    exampleCountDown:              'Example Countdown',
    exampleDateTimeFormat:         'DD.MM.YYYY HH:mm:ss',
    exampleDates:                  'Example dates',
    exampleDatesBrowserLanguage:   'Example dates (browser language)',
    exampleDatesDE:                'Example dates (German)',
    exampleDatesEN:                'Example dates (English)',
    exampleDebounceInput:          'Example debounce input',
    exampleDropdown:               'Example dropdown',
    exampleIcon:                   'Example icon',
    exampleIcons:                  'Example icons',
    exampleImage:                  'Example image',
    exampleImageStorage:           'Example Image-Storage',
    exampleImageUpload:            'Example Image-Upload',
    exampleKeyDown:                'Example KeyEvents',
    exampleKeyDownText:            'You cant press return in this text area:',
    exampleLabels:                 'Example labels',
    exampleLeftRightSplitter:      'Example Left-right-splitter',
    exampleNormalizer:             'Example Normalizer',
    exampleNotifications:          'Example notifications',
    exampleNumberFormat:           'Example number formatting',
    exampleProgress:               'Example progress',
    exampleRepeat:                 'Example repeat',
    examples:                      'Examples',
    home:                          'Home',
    imprint:                       'Imprint',
    inventory:                     'Inventory',
    inventoryDetails:              'Inventory details',
    inventoryHasChanged:           'The inventory has changed',
    itemNotFound:                  'Item not found',
    less:                          'Less',
    loading:                       'Loading...',
    loadingConfiguration:          'Loading server configuration...',
    loadingInventory:              'Loading inventory...',
    loadingLogin:                  'Validating login data...',
    loadingTranslations:           'Loading translations...',
    login:                         'Login',
    loginError:                    'Unfortunately the login failed. Please check your access data and try again.',
    logout:                        'Logout',
    markupTranslationExample:      'Markup translation example',
    newVersionHintLaterButton:     'Later',
    newVersionHintText:            'New version of this application is available',
    newVersionHintUpdateButton:    'Update now',
    pageNotFound:                  'The page could not be found.',
    rangeCombinerFrom:             'from',
    rangeCombinerShort:            ' - ',
    rangeCombinerTo:               'to',
    reloadData:                    'Reload data',
    repeatedText:                  'This text is repeated {{iterationCount}} times',
    screenTooSmallText:            'Your current browser or display size is currently not supported. Please enlarge this window.',
    screenTooSmallTitle:           'Your display or browser window is too small',
    shuffleList:                   'Shuffle list',
    smartUpdater:                  'Smart Updater',
    smartUpdaterData:              'Data',
    smartUpdaterLastUpdate:        'Last update',
    smartUpdaterReload:            'Reload',
    smartUpdaterReloadAll:         'Reload all',
    smartUpdaterReset:             'Reset',
    smartUpdaterResetAll:          'Reset all',
    stagingPasswordPlaceholder:    'Staging password',
    thousandSeparator:             ',',
    webAppCancel:                  'Cancel',
    webAppInstall:                 'Install',
    webAppInstallText:             'You can install the this app to get permanent offline access.',
    webAppOk:                      'OK',
    windowSize:                    'Window size',
    notifications:                 {
        error:   'Error',
        info:    'Info',
        success: 'Success',
        warning: 'Warning',
    },
    // TODO-Skeleton end
});

export default english;
