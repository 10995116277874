//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import LoadingLevel from '@constants/LoadingLevel';

// TODO-Skeleton
const buildActionWithLoadingLevel = (reducer, loadingLevel, text = null) => {
    return {
        reducer,
        prepare: (initialPayload) => {
            return {
                payload: {
                    ...initialPayload,
                    loadingLevel,
                    text,
                },
            };
        },
    };
};

const increaseLoading = (reducer, text = null) => (
    buildActionWithLoadingLevel(reducer, LoadingLevel.increase, text)
);

const decreaseLoading = (reducer, text = null) => (
    buildActionWithLoadingLevel(reducer, LoadingLevel.decrease, text)
);

const increaseLoadingEmptyReducer = (text = null) => (
    buildActionWithLoadingLevel(_.noop, LoadingLevel.increase, text)
);

const decreaseLoadingEmptyReducer = (text = null) => (
    buildActionWithLoadingLevel(_.noop, LoadingLevel.decrease, text)
);

export default {
    increaseLoading,
    decreaseLoading,
    increaseLoadingEmptyReducer,
    decreaseLoadingEmptyReducer,
};
