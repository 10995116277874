//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _        from 'lodash';
import { call } from 'redux-saga/effects';
import { put }  from 'redux-saga/effects';

import * as Api               from '@api/index';
import Hydra                  from '@helper/Hydra';
import { AppSettingsActions } from '@slices/appSettings';

function* fetchAppSettings() {
    const response = yield call(Api.context.appSettings.fetch);

    if (response.ok) {
        const appSettings = Hydra.getMembersFromResponse(response.data);

        yield put(AppSettingsActions.fetchAppSettingsSucceeded({
            appSettings,
        }));
    } else {
        yield put(AppSettingsActions.fetchAppSettingsFailed());
    }
}

function* fetchAppSettingsSucceeded(action) {
    const { appSettings }  = action.payload;
    const activeAppSetting = _.find(appSettings, {
        active: true,
    });

    yield put(AppSettingsActions.setActiveAppSetting(activeAppSetting));
}

function* updateAppSetting(action) {
    const { iri, body } = action.payload;
    const response      = yield call(
        Api.context.appSettings.update,
        iri,
        body,
    );

    if (response.ok) {
        const appSetting = response.data;

        yield put(AppSettingsActions.updateAppSettingSucceeded({
            appSetting,
        }));
    }
}

export default {
    fetchAppSettings,
    fetchAppSettingsSucceeded,
    updateAppSetting,
};
