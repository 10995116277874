//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n     from 'i18next';
import { call } from 'redux-saga/effects';
import { put }  from 'redux-saga/effects';

import * as Api          from '@api/index';
import Notification      from '@helper/Notification';
import { OrdersActions } from '@slices/orders';

function* updateOrderProductStatus(action) {
    const { orderIri, orderProductIri, status } = action.payload;
    const response                              = yield call(
        Api.context.orderProducts.update,
        orderProductIri,
        {
            status,
        },
    );

    if (response.ok) {
        yield put(OrdersActions.fetchOrder({
            iri: orderIri,
        }));
    } else {
        Notification.error(I18n.t('orderProductStatusUpdateFailed'));
    }
}

export default {
    updateOrderProductStatus,
};
