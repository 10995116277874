//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }  from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import * as Api                    from '@api/index';
import Hydra                       from '@helper/Hydra';
import { ProductAdditionsActions } from '@slices/productAdditions';

function* fetchProductAdditions(action) {
    const response = yield call(Api.context.productAdditions.fetch);

    if (response.ok) {
        const productAdditions = Hydra.getMembersFromResponse(response.data);

        yield put(ProductAdditionsActions.fetchProductAdditionsSucceeded({
            productAdditions,
        }));
    } else {
        yield put(ProductAdditionsActions.fetchProductAdditionsFailed());
    }
}

export default {
    fetchProductAdditions,
};
