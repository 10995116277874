//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import jwtDecode from 'jwt-decode';
import _         from 'lodash';

// TODO-Skeleton start
export default class Token {
    static isValidJWTToken = (jwtToken) => {
        return Token.getTimeTillExpiration(jwtToken) > 0;
    };

    static getTimeTillExpiration = (jwtToken) => {
        if (jwtToken) {
            try {
                const decodedToken  = jwtDecode(jwtToken);
                const expiration    = _.get(decodedToken, 'exp', 0) * 1000;
                const currentMillis = Date.now();
                // Max delay in sagas is around 24 days. So we need to limit the expiration to this value.
                const maxExpiration = 2147483646;

                return Math.min(expiration - currentMillis, maxExpiration);
            } catch (error) {
                console.error(`Error while calculating jwt token expiration: "${jwtToken}"`, error);
            }
        }

        return 0;
    };
}
// TODO-Skeleton end
